<template>
  <div class="h-full">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import useAnyListFilter from "@/store/anyListFilter";
import { getAuth } from "@firebase/auth";

useHead({
  titleTemplate: (title) =>
    title ? `${title} | Orion Portal` : "Orion Portal",
});

const { $sentrySetUser } = useNuxtApp();

const { clientVersion } = useRuntimeConfig();

declare global {
  interface Window {
    Appcues: any;
  }
}

watch(
  () => getAuth().currentUser,
  () => {
    const user = getAuth().currentUser;
    $sentrySetUser(user);

    if (user != null && window.Appcues != null) {
      window.Appcues.identify(user.uid, {
        accountId: user.uid,
        firstName: user.displayName,
        email: user.email,
        version: clientVersion,
      });
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

onMounted(() => {
  useAnyListFilter.clearAll();
});
</script>
